<template>
  <component
    :is="computeTag()"
    :class="[
      removeBootstrapStyles(),
      'd-block flex-shrink-0 mw-100 mh-100 zzz-text',
      { 'zzz-text--fa-picto': isFaPictoTextType },
      computeTextFlow(),
      computeTextAlignClasses(),
      computeTextFontFamily(),
      computeTextSizeClasses(),
      computeTextTransformationClasses(),
      computeTextDecorationClasses(),
      computeTextWeightClasses(),
      computeTextStyleClasses(),
      computeTextColorClasses(),
    ]"
  >
    <slot />
  </component>
</template>

<script>
const TextType = {
  FaPicto: "faPicto",
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  Label: "label",
  P: "p",
  Span: "span",
  Raw: "raw",
};

const TextTag = {
  [TextType.FaPicto]: "div",
  [TextType.H1]: "h1",
  [TextType.H2]: "h2",
  [TextType.H3]: "h3",
  [TextType.H4]: "h4",
  [TextType.H5]: "h5",
  [TextType.H6]: "h6",
  [TextType.Label]: "label",
  [TextType.P]: "p",
  [TextType.Span]: "span",
  [TextType.Raw]: "pre",
};

const TextFlowType = {
  MinContent: "minContent",
  Truncate: "truncate",
};

export default {
  name: "ZzzText",

  components: {},

  props: {
    role: String,

    flow: [Array, String], // minContent | truncate

    fontFamilyMono: [Boolean, String],
    fontFamilyTheme: [Boolean, String],

    fontSizeBigger1: [Boolean, String],
    fontSizeBigger2: [Boolean, String],
    fontSizeBigger3: [Boolean, String],
    fontSizeBigger4: [Boolean, String],
    fontSizeBigger5: [Boolean, String],
    fontSizeBigger6: [Boolean, String],
    fontSizeBigger7: [Boolean, String],
    fontSizeBigger8: [Boolean, String],
    fontSizeBigger9: [Boolean, String],
    fontSizeBigger10: [Boolean, String],
    fontSizeNormal: [Boolean, String],
    fontSizeSmaller1: [Boolean, String],
    fontSizeSmaller2: [Boolean, String],
    fontSizeSmaller3: [Boolean, String],
    fontSizeSmaller4: [Boolean, String],
    fontSizeSmaller5: [Boolean, String],
    fontSizeSmaller6: [Boolean, String],

    alignCenter: [Boolean, String],
    alignJustify: [Boolean, String],
    alignLeft: [Boolean, String],
    alignRight: [Boolean, String],

    uppercase: [Boolean, String],

    underlined: [Boolean, String],

    bold: [Boolean, String],

    italic: [Boolean, String],

    colorAlert: [Boolean, String],
    colorDanger: [Boolean, String],
    colorGrey: [Boolean, String],
    colorLightGrey: [Boolean, String],
    colorLighterGrey: [Boolean, String],
    colorSuccess: [Boolean, String],
    colorTheme1: [Boolean, String],
    colorTheme2: [Boolean, String],
    colorTheme3: [Boolean, String],
    colorTheme4: [Boolean, String],
    colorWhite: [Boolean, String],
  },

  data() {
    return {};
  },

  computed: {
    isFaPictoTextType() {
      return this.role === TextType.FaPicto;
    },

    isAnyHTag() {
      return [
        TextTag[TextType.H1],
        TextTag[TextType.H2],
        TextTag[TextType.H3],
        TextTag[TextType.H4],
        TextTag[TextType.H5],
        TextTag[TextType.H6],
      ].includes(TextTag[this.role]);
    },

    isPTag() {
      return TextTag[this.role] === TextTag[TextType.P];
    },

    isPreTag() {
      return TextTag[this.role] === TextTag[TextType.Raw];
    },
  },

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {
    computeTag() {
      const role = this.role ? this.role : null;

      return Object.values(TextType).includes(role)
        ? TextTag[role]
        : TextTag[TextType.Raw];
    },

    computeTextAlignClasses() {
      let classes = [];

      if (this.alignCenter === true) classes.push("text-center");
      if (this.alignCenter === "sm") classes.push("text-sm-center");
      if (this.alignCenter === "md") classes.push("text-md-center");
      if (this.alignCenter === "lg") classes.push("text-lg-center");
      if (this.alignCenter === "xl") classes.push("text-xl-center");

      if (this.alignJustify === true) classes.push("text-justify");
      if (this.alignJustify === "sm") classes.push("text-sm-justify");
      if (this.alignJustify === "md") classes.push("text-md-justify");
      if (this.alignJustify === "lg") classes.push("text-lg-justify");
      if (this.alignJustify === "xl") classes.push("text-xl-justify");

      if (this.alignLeft === true) classes.push("text-start");
      if (this.alignLeft === "sm") classes.push("text-sm-start");
      if (this.alignLeft === "md") classes.push("text-md-start");
      if (this.alignLeft === "lg") classes.push("text-lg-start");
      if (this.alignLeft === "xl") classes.push("text-xl-start");

      if (this.alignRight === true) classes.push("text-end");
      if (this.alignRight === "sm") classes.push("text-sm-end");
      if (this.alignRight === "md") classes.push("text-md-end");
      if (this.alignRight === "lg") classes.push("text-lg-end");
      if (this.alignRight === "xl") classes.push("text-xl-end");

      return classes;
    },

    computeTextColorClasses() {
      let classes = [];

      if (this.colorAlert === true) classes.push("zzz-text--color-alert");
      if (this.colorAlert === "sm") classes.push("zzz-text--color-sm-alert");
      if (this.colorAlert === "md") classes.push("zzz-text--color-md-alert");
      if (this.colorAlert === "lg") classes.push("zzz-text--color-lg-alert");
      if (this.colorAlert === "xl") classes.push("zzz-text--color-xl-alert");

      if (this.colorDanger === true) classes.push("zzz-text--color-danger");
      if (this.colorDanger === "sm") classes.push("zzz-text--color-sm-danger");
      if (this.colorDanger === "md") classes.push("zzz-text--color-md-danger");
      if (this.colorDanger === "lg") classes.push("zzz-text--color-lg-danger");
      if (this.colorDanger === "xl") classes.push("zzz-text--color-xl-danger");

      if (this.colorGrey === true) classes.push("zzz-text--color-grey");
      if (this.colorGrey === "sm") classes.push("zzz-text--color-sm-grey");
      if (this.colorGrey === "md") classes.push("zzz-text--color-md-grey");
      if (this.colorGrey === "lg") classes.push("zzz-text--color-lg-grey");
      if (this.colorGrey === "xl") classes.push("zzz-text--color-xl-grey");

      if (this.colorLightGrey === true)
        classes.push("zzz-text--color-light-grey");
      if (this.colorLightGrey === "sm")
        classes.push("zzz-text--color-sm-light-grey");
      if (this.colorLightGrey === "md")
        classes.push("zzz-text--color-md-light-grey");
      if (this.colorLightGrey === "lg")
        classes.push("zzz-text--color-lg-light-grey");
      if (this.colorLightGrey === "xl")
        classes.push("zzz-text--color-xl-light-grey");

      if (this.colorLighterGrey === true)
        classes.push("zzz-text--color-lighter-grey");
      if (this.colorLighterGrey === "sm")
        classes.push("zzz-text--color-sm-lighter-grey");
      if (this.colorLighterGrey === "md")
        classes.push("zzz-text--color-md-lighter-grey");
      if (this.colorLighterGrey === "lg")
        classes.push("zzz-text--color-lg-lighter-grey");
      if (this.colorLighterGrey === "xl")
        classes.push("zzz-text--color-xl-lighter-grey");

      if (this.colorSuccess === true) classes.push("zzz-text--color-success");
      if (this.colorSuccess === "sm")
        classes.push("zzz-text--color-sm-success");
      if (this.colorSuccess === "md")
        classes.push("zzz-text--color-md-success");
      if (this.colorSuccess === "lg")
        classes.push("zzz-text--color-lg-success");
      if (this.colorSuccess === "xl")
        classes.push("zzz-text--color-xl-success");

      if (this.colorTheme1 === true) classes.push("zzz-text--color-theme-1");
      if (this.colorTheme1 === "sm") classes.push("zzz-text--color-sm-theme-1");
      if (this.colorTheme1 === "md") classes.push("zzz-text--color-md-theme-1");
      if (this.colorTheme1 === "lg") classes.push("zzz-text--color-lg-theme-1");
      if (this.colorTheme1 === "xl") classes.push("zzz-text--color-xl-theme-1");

      if (this.colorTheme2 === true) classes.push("zzz-text--color-theme-2");
      if (this.colorTheme2 === "sm") classes.push("zzz-text--color-sm-theme-2");
      if (this.colorTheme2 === "md") classes.push("zzz-text--color-md-theme-2");
      if (this.colorTheme2 === "lg") classes.push("zzz-text--color-lg-theme-2");
      if (this.colorTheme2 === "xl") classes.push("zzz-text--color-xl-theme-2");

      if (this.colorTheme3 === true) classes.push("zzz-text--color-theme-3");
      if (this.colorTheme3 === "sm") classes.push("zzz-text--color-sm-theme-3");
      if (this.colorTheme3 === "md") classes.push("zzz-text--color-md-theme-3");
      if (this.colorTheme3 === "lg") classes.push("zzz-text--color-lg-theme-3");
      if (this.colorTheme3 === "xl") classes.push("zzz-text--color-xl-theme-3");

      if (this.colorTheme4 === true) classes.push("zzz-text--color-theme-4");
      if (this.colorTheme4 === "sm") classes.push("zzz-text--color-sm-theme-4");
      if (this.colorTheme4 === "md") classes.push("zzz-text--color-md-theme-4");
      if (this.colorTheme4 === "lg") classes.push("zzz-text--color-lg-theme-4");
      if (this.colorTheme4 === "xl") classes.push("zzz-text--color-xl-theme-4");

      if (this.colorWhite === true) classes.push("zzz-text--color-white");
      if (this.colorWhite === "sm") classes.push("zzz-text--color-sm-white");
      if (this.colorWhite === "md") classes.push("zzz-text--color-md-white");
      if (this.colorWhite === "lg") classes.push("zzz-text--color-lg-white");
      if (this.colorWhite === "xl") classes.push("zzz-text--color-xl-white");

      return classes;
    },

    computeTextDecorationClasses() {
      let classes = [];

      if (this.underlined === true) classes.push("zzz-text--underlined");
      if (this.underlined === "sm") classes.push("zzz-text--sm-underlined");
      if (this.underlined === "md") classes.push("zzz-text--md-underlined");
      if (this.underlined === "lg") classes.push("zzz-text--lg-underlined");
      if (this.underlined === "xl") classes.push("zzz-text--xl-underlined");

      return classes;
    },

    computeTextFlow() {
      function isValidTextFlowValue(value) {
        return [undefined, true, "sm", "md", "lg", "xl"].includes(value);
      }

      let classes = [];

      let flow = null;
      if (this.flow) {
        if (
          this.flow === TextFlowType.Truncate ||
          (this.flow[0] === TextFlowType.Truncate &&
            isValidTextFlowValue(this.flow[1]))
        )
          flow = TextFlowType.Truncate;
        else if (
          this.flow === TextFlowType.MinContent ||
          (this.flow[0] === TextFlowType.MinContent &&
            isValidTextFlowValue(this.flow[1]))
        )
          flow = TextFlowType.MinContent;
      }

      switch (flow) {
        case TextFlowType.Truncate:
          if (this.flow[1] === "sm") classes.push("text-sm-truncate");
          if (this.flow[1] === "md") classes.push("text-md-truncate");
          if (this.flow[1] === "lg") classes.push("text-lg-truncate");
          if (this.flow[1] === "xl") classes.push("text-xl-truncate");
          else classes.push("text-break text-truncate");
          break;

        case TextFlowType.MinContent:
          if (this.flow[1] === "sm") classes.push("text-sm-min-content");
          if (this.flow[1] === "md") classes.push("text-md-min-content");
          if (this.flow[1] === "xl") classes.push("text-xl-min-content");
          if (this.flow[1] === "lg") classes.push("text-lg-min-content");
          else classes.push("text-min-content");
          break;

        default:
          classes.push("text-break");
          break;
      }

      return classes;
    },

    computeTextFontFamily() {
      let classes = [];

      if (this.fontFamilyMono === true)
        classes.push("zzz-text--font-family-mono");
      if (this.fontFamilyMono === "sm")
        classes.push("zzz-text--font-family-sm-mono");
      if (this.fontFamilyMono === "md")
        classes.push("zzz-text--font-family-md-mono");
      if (this.fontFamilyMono === "lg")
        classes.push("zzz-text--font-family-lg-mono");
      if (this.fontFamilyMono === "xl")
        classes.push("zzz-text--font-family-lg-mono");

      if (this.fontFamilyTheme === true)
        classes.push("zzz-text--font-family-theme");
      if (this.fontFamilyTheme === "sm")
        classes.push("zzz-text--font-family-sm-theme");
      if (this.fontFamilyTheme === "md")
        classes.push("zzz-text--font-family-md-theme");
      if (this.fontFamilyTheme === "lg")
        classes.push("zzz-text--font-family-lg-theme");
      if (this.fontFamilyTheme === "xl")
        classes.push("zzz-text--font-family-lg-theme");

      return classes;
    },

    computeTextSizeClasses() {
      let classes = [];

      if (this.fontSizeBigger1 === true)
        classes.push("zzz-text--font-size-bigger-1");
      if (this.fontSizeBigger1 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-1");
      if (this.fontSizeBigger1 === "md")
        classes.push("zzz-text--font-size-md-bigger-1");
      if (this.fontSizeBigger1 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-1");
      if (this.fontSizeBigger1 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-1");

      if (this.fontSizeBigger2 === true)
        classes.push("zzz-text--font-size-bigger-2");
      if (this.fontSizeBigger2 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-2");
      if (this.fontSizeBigger2 === "md")
        classes.push("zzz-text--font-size-md-bigger-2");
      if (this.fontSizeBigger2 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-2");
      if (this.fontSizeBigger2 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-2");

      if (this.fontSizeBigger3 === true)
        classes.push("zzz-text--font-size-bigger-3");
      if (this.fontSizeBigger3 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-3");
      if (this.fontSizeBigger3 === "md")
        classes.push("zzz-text--font-size-md-bigger-3");
      if (this.fontSizeBigger3 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-3");
      if (this.fontSizeBigger3 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-3");

      if (this.fontSizeBigger4 === true)
        classes.push("zzz-text--font-size-bigger-4");
      if (this.fontSizeBigger4 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-4");
      if (this.fontSizeBigger4 === "md")
        classes.push("zzz-text--font-size-md-bigger-4");
      if (this.fontSizeBigger4 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-4");
      if (this.fontSizeBigger4 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-4");

      if (this.fontSizeBigger5 === true)
        classes.push("zzz-text--font-size-bigger-5");
      if (this.fontSizeBigger5 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-5");
      if (this.fontSizeBigger5 === "md")
        classes.push("zzz-text--font-size-md-bigger-5");
      if (this.fontSizeBigger5 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-5");
      if (this.fontSizeBigger5 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-5");

      if (this.fontSizeBigger6 === true)
        classes.push("zzz-text--font-size-bigger-6");
      if (this.fontSizeBigger6 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-6");
      if (this.fontSizeBigger6 === "md")
        classes.push("zzz-text--font-size-md-bigger-6");
      if (this.fontSizeBigger6 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-6");
      if (this.fontSizeBigger6 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-6");

      if (this.fontSizeBigger7 === true)
        classes.push("zzz-text--font-size-bigger-7");
      if (this.fontSizeBigger7 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-7");
      if (this.fontSizeBigger7 === "md")
        classes.push("zzz-text--font-size-md-bigger-7");
      if (this.fontSizeBigger7 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-7");
      if (this.fontSizeBigger7 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-7");

      if (this.fontSizeBigger8 === true)
        classes.push("zzz-text--font-size-bigger-8");
      if (this.fontSizeBigger8 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-8");
      if (this.fontSizeBigger8 === "md")
        classes.push("zzz-text--font-size-md-bigger-8");
      if (this.fontSizeBigger8 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-8");
      if (this.fontSizeBigger8 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-8");

      if (this.fontSizeBigger9 === true)
        classes.push("zzz-text--font-size-bigger-9");
      if (this.fontSizeBigger9 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-9");
      if (this.fontSizeBigger9 === "md")
        classes.push("zzz-text--font-size-md-bigger-9");
      if (this.fontSizeBigger9 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-9");
      if (this.fontSizeBigger9 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-9");

      if (this.fontSizeBigger10 === true)
        classes.push("zzz-text--font-size-bigger-10");
      if (this.fontSizeBigger10 === "sm")
        classes.push("zzz-text--font-size-sm-bigger-10");
      if (this.fontSizeBigger10 === "md")
        classes.push("zzz-text--font-size-md-bigger-10");
      if (this.fontSizeBigger10 === "lg")
        classes.push("zzz-text--font-size-lg-bigger-10");
      if (this.fontSizeBigger10 === "xl")
        classes.push("zzz-text--font-size-xl-bigger-10");

      if (this.fontSizeNormal === true)
        classes.push("zzz-text--font-size-normal");
      if (this.fontSizeNormal === "sm")
        classes.push("zzz-text--font-size-sm-normal");
      if (this.fontSizeNormal === "md")
        classes.push("zzz-text--font-size-md-normal");
      if (this.fontSizeNormal === "lg")
        classes.push("zzz-text--font-size-lg-normal");
      if (this.fontSizeNormal === "xl")
        classes.push("zzz-text--font-size-xl-normal");

      if (this.fontSizeSmaller1 === true)
        classes.push("zzz-text--font-size-smaller-1");
      if (this.fontSizeSmaller1 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-1");
      if (this.fontSizeSmaller1 === "md")
        classes.push("zzz-text--font-size-md-smaller-1");
      if (this.fontSizeSmaller1 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-1");
      if (this.fontSizeSmaller1 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-1");

      if (this.fontSizeSmaller2 === true)
        classes.push("zzz-text--font-size-smaller-2");
      if (this.fontSizeSmaller2 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-2");
      if (this.fontSizeSmaller2 === "md")
        classes.push("zzz-text--font-size-md-smaller-2");
      if (this.fontSizeSmaller2 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-2");
      if (this.fontSizeSmaller2 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-2");

      if (this.fontSizeSmaller3 === true)
        classes.push("zzz-text--font-size-smaller-3");
      if (this.fontSizeSmaller3 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-3");
      if (this.fontSizeSmaller3 === "md")
        classes.push("zzz-text--font-size-md-smaller-3");
      if (this.fontSizeSmaller3 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-3");
      if (this.fontSizeSmaller3 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-3");

      if (this.fontSizeSmaller4 === true)
        classes.push("zzz-text--font-size-smaller-4");
      if (this.fontSizeSmaller4 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-4");
      if (this.fontSizeSmaller4 === "md")
        classes.push("zzz-text--font-size-md-smaller-4");
      if (this.fontSizeSmaller4 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-4");
      if (this.fontSizeSmaller4 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-4");

      if (this.fontSizeSmaller5 === true)
        classes.push("zzz-text--font-size-smaller-5");
      if (this.fontSizeSmaller5 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-5");
      if (this.fontSizeSmaller5 === "md")
        classes.push("zzz-text--font-size-md-smaller-5");
      if (this.fontSizeSmaller5 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-5");
      if (this.fontSizeSmaller5 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-5");

      if (this.fontSizeSmaller6 === true)
        classes.push("zzz-text--font-size-smaller-6");
      if (this.fontSizeSmaller6 === "sm")
        classes.push("zzz-text--font-size-sm-smaller-6");
      if (this.fontSizeSmaller6 === "md")
        classes.push("zzz-text--font-size-md-smaller-6");
      if (this.fontSizeSmaller6 === "lg")
        classes.push("zzz-text--font-size-lg-smaller-6");
      if (this.fontSizeSmaller6 === "xl")
        classes.push("zzz-text--font-size-xl-smaller-6");

      return classes;
    },

    computeTextStyleClasses() {
      let classes = [];

      if (this.italic === true) classes.push("fst-italic");
      if (this.italic === "sm") classes.push("fst-sm-italic");
      if (this.italic === "md") classes.push("fst-md-italic");
      if (this.italic === "lg") classes.push("fst-lg-italic");
      if (this.italic === "xl") classes.push("fst-xl-italic");

      return classes;
    },

    computeTextTransformationClasses() {
      let classes = [];

      if (this.uppercase === true) classes.push("text-uppercase");
      if (this.uppercase === "sm") classes.push("text-sm-uppercase");
      if (this.uppercase === "md") classes.push("text-md-uppercase");
      if (this.uppercase === "lg") classes.push("text-lg-uppercase");
      if (this.uppercase === "xl") classes.push("text-xl-uppercase");

      return classes;
    },

    computeTextWeightClasses() {
      let classes = [];

      if (this.bold === true) classes.push("fw-bold");
      if (this.bold === "sm") classes.push("fw-sm-bold");
      if (this.bold === "md") classes.push("fw-md-bold");
      if (this.bold === "lg") classes.push("fw-lg-bold");
      if (this.bold === "xl") classes.push("fw-xl-bold");

      return classes;
    },

    removeBootstrapStyles() {
      let classes = ["fw-normal"];

      if (this.isAnyHTag || this.isPTag || this.isPreTag) classes.push("mb-0");

      if (this.isPreTag) classes.push("fs-unset");

      return classes;
    },
  },
};
</script>

<style lang="scss">
/* =========================================================================
 * TEXT
 * ========================================================================= */

/* TEXT BASIS
 * ------------------------------------------------------------------------- */

.zzz-text {
  line-height: 1.5;
}

/* SPECIFIC TEXTS
 * ------------------------------------------------------------------------- */

// For Font Awesome
.zzz-text--fa-picto {
  font-size: $zzz-font-size--root; // Must be the mirror of the font size set on the body tag
  line-height: 1;
}

@mixin set-color-classes() {
  $text-color-class-name-basis: "zzz-text--color";
  $property: "color";
  $colors: (
    "alert": $zzz-color--warning,
    "danger": $zzz-color--danger,
    "grey": $zzz-color--grey-6,
    "light-grey": $zzz-color--grey-5,
    "lighter-grey": $zzz-color--grey-3,
    "success": $zzz-color--success,
    "theme-1": $zzz-color--theme-1,
    "theme-2": $zzz-color--theme-2,
    "theme-3": $zzz-color--theme-3,
    "theme-4": $zzz-color--theme-4,
    "white": $zzz-color--white,
  );

  .#{$text-color-class-name-basis}- {
    @include generate-class("unset", $property, unset);
    @each $key, $value in $colors {
      @include generate-class($key, $property, $value);
    }
  }
  @each $i-key, $i-value in $viewport-breakpoints {
    @media (min-width: $i-value) {
      .#{$text-color-class-name-basis}-#{$i-key}- {
        @include generate-class("unset", $property, unset);
        @each $j-key, $j-value in $colors {
          @include generate-class($j-key, $property, $j-value);
        }
      }
    }
  }
}

@mixin set-font-family-classes() {
  $text-font-family-class-name-basis: "zzz-text--font-family";
  $property: "font-family";
  $font-families: (
    "mono": $zzz-font-family--mono,
    "primary": $zzz-font-family--primary,
  );

  .#{$text-font-family-class-name-basis}- {
    @include generate-class("unset", $property, unset);
    @each $key, $value in $font-families {
      @include generate-class($key, $property, $value);
    }
  }
  @each $i-key, $i-value in $viewport-breakpoints {
    @media (min-width: $i-value) {
      .#{$text-font-family-class-name-basis}-#{$i-key}- {
        @include generate-class("unset", $property, unset);
        @each $j-key, $j-value in $font-families {
          @include generate-class($j-key, $property, $j-value);
        }
      }
    }
  }
}

@mixin set-font-size-classes() {
  $font-size-class-name-basis: "zzz-text--font-size";
  $property: "font-size";
  $font-sizes: (
    "bigger-1": $zzz-font-size--bigger-1,
    "bigger-2": $zzz-font-size--bigger-2,
    "bigger-3": $zzz-font-size--bigger-3,
    "bigger-4": $zzz-font-size--bigger-4,
    "bigger-5": $zzz-font-size--bigger-5,
    "bigger-6": $zzz-font-size--bigger-6,
    "bigger-7": $zzz-font-size--bigger-7,
    "bigger-8": $zzz-font-size--bigger-8,
    "bigger-9": $zzz-font-size--bigger-9,
    "bigger-10": $zzz-font-size--bigger-10,
    "normal": $zzz-font-size--normal,
    "smaller-1": $zzz-font-size--smaller-1,
    "smaller-2": $zzz-font-size--smaller-2,
    "smaller-3": $zzz-font-size--smaller-3,
    "smaller-4": $zzz-font-size--smaller-4,
    "smaller-5": $zzz-font-size--smaller-5,
    "smaller-6": $zzz-font-size--smaller-6,
  );

  .#{$font-size-class-name-basis}- {
    @each $key, $value in $font-sizes {
      @include generate-class($key, $property, $value);
    }
  }
  @each $i-key, $i-value in $viewport-breakpoints {
    @media (min-width: $i-value) {
      .#{$font-size-class-name-basis}-#{$i-key}- {
        @include generate-class("unset", $property, unset);
        @each $j-key, $j-value in $font-sizes {
          @include generate-class($j-key, $property, $j-value);
        }
      }
    }
  }
}

@include set-font-family-classes();
@include set-font-size-classes();
@include set-color-classes();
</style>
