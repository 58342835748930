<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
@import "@/sass/main";

/* =========================================================================
 * APP
 * ========================================================================= */
</style>
