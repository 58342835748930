<template>
  <ZzzSkeleton>
    <div
      class="d-flex flex-grow-1 flex-column text-darker-grey zzz-l-layout zzz-l-default-layout"
      :style="{
        backgroundImage: backgroundImageSrc
          ? 'url(' + backgroundImageSrc + ')'
          : null,
      }"
    >
      <!-- <header class="d-flex z-index-1 flex-column zzz-l-header">
        <div class="d-flex justify-content-center zzz-navbar">
          <div
            class="d-flex container-xxl p-3 justify-content-between align-items-center"
          >
            <div class="d-flex flex-column pe-2">
              <ZzzBrandLogo />
            </div>

            <div class="d-flex flex-column ps-2">
              <ZzzMainMenu />
            </div>
          </div>
        </div>
      </header> -->

      <div class="d-flex z-index-0 flex-grow-1 min-h-0 flex-column">
        <!-- Left sidebar -->
        <!-- TODO -->

        <main class="d-flex z-index-1 flex-grow-1 zzz-l-main">
          <div class="d-flex container-xxl flex-grow-1 p-3 flex-column">
            <slot />
          </div>
        </main>

        <!-- Right sidebar -->
        <!-- TODO -->
      </div>
    </div>
  </ZzzSkeleton>
</template>

<script>
// Misc
import { computeAssetFileSrc } from "@/helpers";
// Layout components
import ZzzSkeleton from "./Skeleton.vue";
// Module components
// import ZzzBrandLogo from "@/components/modules/BrandLogo";
// import ZzzMainMenu from "@/components/modules/MainMenu";

export default {
  name: "ZzzDefaultLayout",

  components: {
    // Layout components
    ZzzSkeleton,
    // Module components
    // ZzzBrandLogo,
    // ZzzMainMenu,
  },

  props: {
    backgroundImageFile: String,
  },

  data() {
    return {};
  },

  computed: {
    backgroundImageSrc() {
      return computeAssetFileSrc(this.backgroundImageFile);
    },
  },

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
/* =========================================================================
 * DEFAULT LAYOUT
 * ========================================================================= */

.zzz-l-default-layout {
  background-position: center;
  background-size: cover;
}
</style>
