<template>
  <a
    :href="href"
    :target="noTargetBlank ? null : '_blank'"
    class="d-inline-flex mw-100 justify-content-center align-items-center rounded-1 zzz-link"
  >
    <ZzzText v-if="faPictoClass" role="faPicto"><i :class="faPictoClass"></i></ZzzText>

    <div
      v-if="label"
      :class="['d-inline-flex flex-grow-1 min-w-0', { 'ms-2': faPictoClass }]"
    >
      <ZzzText role="span" alignLeft bold>{{ label }}</ZzzText>
    </div>
  </a>
</template>

<script>
import ZzzText from "@/components/widgets/Text.vue";

export default {
  name: "ZzzTextLink",

  components: {
    ZzzText,
  },

  props: {
    href: String,
    noTargetBlank: Boolean,
    faPictoClass: String,
    label: String,
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
/* =========================================================================
 * LINK
 * ========================================================================= */

.zzz-link {
}
</style>
