<template>
  <div
    :class="[
      'd-flex m-auto w-100 flex-column bg-white zzz-block',
      { border: border },
      { 'flex-grow-1': fullHeight },
      { 'zzz-block--limited-width': limitedWidth },
      { shadow: shadow },
      { 'rounded-1': rounded },
    ]"
  >
    <div
      v-if="bannerImageFile"
      class="d-flex justify-content-center align-items-center zzz-block__header"
    >
      <img
        :src="bannerImageSrc"
        alt="Banner"
        :class="[
          'w-100 zzz-block__banner',
          { 'rounded-tl-1 rounded-tr-1': rounded },
        ]"
      />
    </div>

    <div class="d-flex flex-column zzz-block__body">
      <div
        v-if="logoImageFile || title"
        class="row pt-5 px-4 pb-3 g-0 bg-lite-grey zzz-block__title-section"
      >
        <div
          v-if="logoImageFile"
          class="d-flex col-3 pe-2 justify-content-end align-items-center"
        >
          <img
            :src="logoImageSrc"
            alt="Banner"
            class="rounded-2 zzz-block__logo"
          />
        </div>

        <div class="d-flex col-9 ps-2 align-items-center">
          <ZzzText v-if="title" role="h2">{{ title }}</ZzzText>
        </div>
      </div>

      <div class="d-flex pt-3 px-4 pb-5 flex-column">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
// Misc
import { computeAssetFileSrc } from "@/helpers";
// Widget components
import ZzzText from "@/components/widgets/Text.vue";

export default {
  name: "ZzzBlock",

  components: {
    ZzzText,
  },

  props: {
    bannerImageFile: String,
    logoImageFile: String,
    title: String,
    limitedWidth: Boolean,
    border: Boolean,
    fullHeight: Boolean,
    shadow: Boolean,
    rounded: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    logoImageSrc() {
      return computeAssetFileSrc(this.logoImageFile);
    },

    bannerImageSrc() {
      return computeAssetFileSrc(this.bannerImageFile);
    },
  },

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
/* =========================================================================
* BLOCK
* ========================================================================= */

.zzz-block {
  &--limited-width {
    max-width: 512px;
  }

  &__header {
  }

  &__banner {
    height: 256px;
  }

  &__title-section {
  }

  &__logo {
    max-height: 64px;
  }
}
</style>
