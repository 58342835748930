<template>
  <component
    :is="computeTag()"
    :href="href"
    :target="noTargetBlank ? null : '_blank'"
    :class="[
      'd-inline-flex mw-100 py-2 px-3 justify-content-center align-items-center rounded-3 shadow-sm zzz-button',
      { 'text-decoration-none': href },
      computeTheme(),
    ]"
  >
    <ZzzText v-if="faPictoClass" role="faPicto" fontSizeBigger10 class="m-1">
      <i :class="faPictoClass"></i>
    </ZzzText>

    <div
      v-if="label"
      :class="[
        'd-inline-flex flex-grow-1 min-w-0',
        { 'ms-1': faPictoClass },
        computeTheme(),
      ]"
    >
      <ZzzText role="span" alignLeft bold>{{ label }}</ZzzText>
    </div>
  </component>
</template>

<script>
import ZzzText from "@/components/widgets/Text.vue";

export default {
  name: "ZzzButton",

  components: {
    ZzzText,
  },

  props: {
    href: String,
    noTargetBlank: Boolean,
    faPictoClass: String,
    label: String,
    theme: String,
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {
    computeTag() {
      return this.href ? "a" : "div";
    },

    computeTheme() {
      let classes = [];

      if (this.theme === "success")
        classes.push("bg-theme-success zzz-button--theme-success");
      if (this.theme === "neutral")
        classes.push("bg-theme-neutral zzz-button--theme-success");
      if (this.theme === "warning")
        classes.push("bg-theme-warning zzz-button--theme-warning");
      if (this.theme === "danger")
        classes.push("bg-theme-danger zzz-button--theme-danger");
      if (this.theme === "1") classes.push("bg-theme-1 zzz-button--theme-1");
      if (this.theme === "2") classes.push("bg-theme-2 zzz-button--theme-2");
      if (this.theme === "3") classes.push("bg-theme-3 zzz-button--theme-3");
      if (this.theme === "4") classes.push("bg-theme-4 zzz-button--theme-4");
      if (this.theme === "discord") classes.push("zzz-button--theme-discord");
      if (this.theme === "facebook") classes.push("zzz-button--theme-facebook");
      if (this.theme === "instagram")
        classes.push("zzz-button--theme-instagram");

      return classes;
    },
  },
};
</script>

<style lang="scss">
/* =========================================================================
 * BUTTON
 * ========================================================================= */

.zzz-button {
  &--theme-success {
  }

  &--theme-neutral {
  }

  &--theme-warning {
  }

  &--theme-danger {
  }

  &--theme-1 {
  }

  &--theme-2 {
  }

  &--theme-3 {
  }

  &--theme-4 {
  }

  &--theme-discord {
    background-color: #7289da;
    color: $zzz-color--white;

    &:hover {
      color: #7289da;
      background-color: $zzz-color--white;
    }
  }

  &--theme-facebook {
    background-color: #4267b2;
    color: $zzz-color--white;

    &:hover {
      color: #4267b2;
      background-color: $zzz-color--white;
    }
  }

  &--theme-instagram {
    background-color: #e1306c;
    color: $zzz-color--white;

    &:hover {
      color: #e1306c;
      background-color: $zzz-color--white;
    }
  }
}
</style>
