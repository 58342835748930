// Vue
import { createRouter, createWebHistory } from "vue-router";
// View components
// import Home from "@/components/views/Home";
import Customer from "@/components/views/Customer";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      // component: Home,
      component: Customer,
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "@/components/views/About"),
    // },
    // {
    //   path: "/c",
    //   name: "customer",
    //   component: Customer,
    // },
    // {
    //   name: "Customer",
    //   path: "/c",
    //   component: Customer,
    // },
  ],
});
