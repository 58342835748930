<template>
  <ZzzDefaultLayout>
    <ZzzBlock
      bannerImageFile="banner.jpeg"
      logoImageFile="logo.jpg"
      title="Église Évangélique Pierres Vivantes"
      border
      limitedWidth
      shadow
      rounded
    >
      <ZzzCoord>
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Adresse :</ZzzText
          >
        </template>
        <ZzzText role="span">23 ruelle Marion</ZzzText>
        <ZzzText role="span">01000 Bourg-en-Bresse</ZzzText>
      </ZzzCoord>

      <ZzzCoord class="mt-3">
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Horaires :</ZzzText
          >
        </template>
        <ZzzText role="span">Accueil : dimanche de 09:45 à 10:15</ZzzText>
        <ZzzText role="span">Culte : dimanche de 10:15 à 11:45</ZzzText>
      </ZzzCoord>

      <ZzzCoord class="mt-3">
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Téléphone :</ZzzText
          >
        </template>
        <ZzzText role="span">+33 4 74 45 17 19</ZzzText>
      </ZzzCoord>

      <ZzzCoord class="mt-3">
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Courriel :</ZzzText
          >
        </template>
        <ZzzText role="span">
          <span>{{ emailAddressPart1 }}</span>
          <span>{{ emailAddressPart2 }}</span>
          <span>{{ emailAddressPart3 }}</span>
        </ZzzText>
      </ZzzCoord>

      <ZzzCoord class="mt-3">
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Site Web :</ZzzText
          >
        </template>
        <ZzzTextLink href="https://eepv.fr" target="_blank" label="eepv.fr" />
      </ZzzCoord>

      <ZzzCoord class="mt-3">
        <template #label>
          <ZzzText role="span" alignRight fontSizeSmaller5 colorGrey
            >Communauté :</ZzzText
          >
        </template>
        <ZzzList offset="2">
          <ZzzButton
            href="https://www.facebook.com/eepv01/"
            faPictoClass="fa-brands fa-facebook"
            theme="facebook"
            class="m-2"
          />

          <ZzzButton
            href="https://www.instagram.com/eglisepierresvivantes"
            faPictoClass="fa-brands fa-instagram"
            theme="instagram"
            class="m-2"
          />

          <ZzzButton
            href="https://discord.gg/SpnAg7Ctmn"
            faPictoClass="fa-brands fa-discord"
            label="Rejoindre Discord"
            theme="discord"
            class="m-2"
          />
        </ZzzList>
      </ZzzCoord>
    </ZzzBlock>
  </ZzzDefaultLayout>
</template>

<script>
// Layout components
import ZzzDefaultLayout from "@/components/layouts/DefaultLayout";
// Widget components
import ZzzBlock from "@/components/widgets/Block.vue";
import ZzzButton from "@/components/widgets/Button.vue";
import ZzzCoord from "@/components/widgets/Coord.vue";
import ZzzList from "@/components/widgets/List.vue";
import ZzzText from "@/components/widgets/Text.vue";
import ZzzTextLink from "@/components/widgets/TextLink.vue";

export default {
  name: "ZzzCustomer",

  components: {
    // Layout components
    ZzzDefaultLayout,
    // Widget components
    ZzzBlock,
    ZzzButton,
    ZzzCoord,
    ZzzList,
    ZzzText,
    ZzzTextLink,
  },

  props: {},

  data() {
    return {
      emailAddressPart1: "contact",
      emailAddressPart2: "@eepv",
      emailAddressPart3: ".fr",
    };
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss"></style>
