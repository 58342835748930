<template>
  <div class="row g-0 align-items-baseline zzz-coord">
    <div class="col-3 pe-2">
      <slot name="label" />
    </div>
    <div class="col-9 ps-2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZzzCoord",

  components: {
    // ZzzText,
  },

  props: {
    href: String,
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
/* =========================================================================
 * COORD
 * ========================================================================= */

.zzz-coord {
}
</style>
