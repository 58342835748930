<template>
  <div class="d-flex position-relative vh-100 overflow-auto flex-column l-skeleton">
    <!-- Relative-positioned nodes  -->
    <div class="d-flex position-relative z-index-0 flex-grow-1 flex-column">
      <slot />
    </div>

    <!-- Absolute-positioned nodes  -->
    <!-- TODO -->
  </div>
</template>

<script>
export default {
  name: "ZzzSkeleton",

  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {},
};
</script>

<style lang="scss">
/* =========================================================================
 * SKELETON
 * ========================================================================= */

.l-skeleton {
}
</style>
