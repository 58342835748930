<template>
  <div
    :class="['d-flex flex-wrap align-items-start zzz-list', computeMargins()]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ZzzList",

  components: {},

  props: {
    offset: [Number, String],
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  beforeCreate() {},

  created() {},

  beforeMount() {},

  mounted() {},

  beforeUpdate() {},

  updated() {},

  beforeUnmount() {},

  unmounted() {},

  methods: {
    computeMargins() {
      const offset = this.offset ? parseInt(this.offset) : null;

      return offset && [1, 2, 3, 4, 5].includes(offset) ? "m-n" + offset : null;
    },
  },
};
</script>

<style lang="scss">
/* =========================================================================
 * WRAP LIST
 * ========================================================================= */

.zzz-list {
  &__item {
  }
}
</style>
